import { getStatuses } from '../../lib/statuses'
import { localeAwareUpperCase } from '../../lib/words'
import { Key } from './Key'
import { useEffect } from 'react'

type Props = {
    onChar: (value: string) => void
    onDelete: () => void
    onEnter: () => void
    solution: string
    guesses: string[]
    isRevealing?: boolean
}

export const Keyboard = ({ onChar, onDelete, onEnter, solution, guesses, isRevealing }: Props) => {
    const charStatuses = getStatuses(solution, guesses)

    const onClick = (value: string) => {
        if (value === 'ENTER') {
            onEnter()
        } else if (value === 'DELETE') {
            onDelete()
        } else {
            onChar(value)
        }
    }

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.code === 'Enter') {
                onEnter()
            } else if (e.code === 'Backspace') {
                onDelete()
            } else {
                const key = localeAwareUpperCase(e.key)
                if ((key.length === 1 && (key === 'Ž' || key === 'Š' || key === 'Č')) || (key >= 'A' && key <= 'Z')) {
                    onChar(key)
                }
            }
        }
        window.addEventListener('keyup', listener)
        return () => {
            window.removeEventListener('keyup', listener)
        }
    }, [onEnter, onDelete, onChar])

    return (
        <div>
            <div className="mb-1 flex justify-center">
                {['E', 'R', 'T', 'Z', 'U', 'I', 'O', 'P', 'Š'].map((key) => (
                    <Key value={key} key={key} onClick={onClick} status={charStatuses[key]} isRevealing={isRevealing} />
                ))}
            </div>
            <div className="mb-1 flex justify-center">
                {['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Č'].map((key) => (
                    <Key value={key} key={key} onClick={onClick} status={charStatuses[key]} isRevealing={isRevealing} />
                ))}
            </div>
            <div className="flex justify-center">
                <Key width={65.4} value="ENTER" onClick={onClick}>
                    Enter
                </Key>
                {['C', 'V', 'B', 'N', 'M', 'Ž'].map((key) => (
                    <Key value={key} key={key} onClick={onClick} status={charStatuses[key]} isRevealing={isRevealing} />
                ))}
                <Key width={65.4} value="DELETE" onClick={onClick}>
                    Briši
                </Key>
            </div>
        </div>
    )
}
