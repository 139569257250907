import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
    isOpen: boolean
    handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
    return (
        <BaseModal title="Kako postati besedno maestro?" isOpen={isOpen} handleClose={handleClose}>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                Ugani besedo v šestih poskusih. Po vsakem ugibanju se bo barva ploščic spremenila, da ti pokaže, kako
                blizu si bil pravi besedi. Malo kot igranje vroče-hladno, ampak z besedami in barvami!
            </p>

            <div className="mb-1 mt-4 flex justify-center">
                <Cell isRevealing={true} isCompleted={true} value="S" status="correct" />
                <Cell value="T" isCompleted={true} />
                <Cell value="R" isCompleted={true} />
                <Cell value="A" isCompleted={true} />
                <Cell value="N" isCompleted={true} />
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                Črka S je v besedi in na pravem mestu. Kot nalašč, kajne?
            </p>

            <div className="mb-1 mt-4 flex justify-center">
                <Cell value="P" isCompleted={true} />
                <Cell value="I" isCompleted={true} />
                <Cell isRevealing={true} isCompleted={true} value="L" status="present" />
                <Cell value="O" isCompleted={true} />
                <Cell value="T" isCompleted={true} />
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                Črka L je v besedi, ampak na napačnem mestu. Kot pes na zabavi mačk!
            </p>

            <div className="mb-1 mt-4 flex justify-center">
                <Cell value="Ž" isCompleted={true} />
                <Cell value="U" isCompleted={true} />
                <Cell value="Ž" isCompleted={true} />
                <Cell isRevealing={true} isCompleted={true} value="E" status="absent" />
                <Cell value="K" isCompleted={true} />
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-300">
                Črke E v besedi ni na nobenem mestu. Kot sneg na plaži, ga preprosto ni!
            </p>
        </BaseModal>
    )
}
