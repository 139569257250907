export const VALID_GUESSES = [
    'akord',
    'vrana',
    'misel',
    'maska',
    'plača',
    'kolaž',
    'jedro',
    'morje',
    'tarča',
    'usoda',
    'lopov',
    'žetev',
    'faran',
    'trnek',
    'boben',
    'ukana',
    'grozd',
    'deset',
    'ekran',
    'arest',
    'čmrlj',
    'tabla',
    'ropar',
    'borza',
    'jeklo',
    'domet',
    'tašča',
    'leglo',
    'sloga',
    'lonec',
    'nalet',
    'pleša',
    'cekin',
    'velur',
    'etuda',
    'vzrok',
    'atlas',
    'pesem',
    'utrip',
    'jakna',
    'jahač',
    'izvid',
    'napoj',
    'sršen',
    'curek',
    'disko',
    'izpis',
    'tiran',
    'zajem',
    'humus',
    'biser',
    'čekan',
    'citre',
    'sklop',
    'stoja',
    'afekt',
    'mreža',
    'heroj',
    'kokos',
    'slava',
    'akter',
    'sejem',
    'ponor',
    'angel',
    'arzen',
    'motel',
    'otrob',
    'jezik',
    'frula',
    'okraj',
    'bunda',
    'nemoč',
    'najem',
    'modrc',
    'agent',
    'notes',
    'tovor',
    'hrast',
    'torta',
    'malha',
    'enica',
    'maček',
    'fagot',
    'arija',
    'kavka',
    'zadah',
    'bezeg',
    'hehet',
    'mleko',
    'čudež',
    'tekst',
    'jahta',
    'smrad',
    'skica',
    'idiot',
    'lajna',
    'vodja',
    'omelo',
    'scena',
    'opora',
    'živež',
    'šifra',
    'etapa',
    'proso',
    'cista',
    'totem',
    'hvala',
    'odlog',
    'akord',
    'zmaga',
    'foton',
    'navor',
    'heker',
    'pavza',
    'blesk',
    'dotik',
    'rondo',
    'cucek',
    'hojca',
    'miška',
    'jager',
    'mejak',
    'talka',
    'izvod',
    'izpit',
    'klapa',
    'optik',
    'cimer',
    'lasje',
    'trapa',
    'oljka',
    'psalm',
    'fetus',
    'harem',
    'zapis',
    'helij',
    'zapor',
    'runda',
    'avans',
    'lipid',
    'pater',
    'zaliv',
    'krtek',
    'smola',
    'božič',
    'dečko',
    'lubje',
    'otrok',
    'volja',
    'mizar',
    'izziv',
    'atlet',
    'imbus',
    'obvod',
    'videz',
    'zglob',
    'ropot',
    'okras',
    'citat',
    'zaboj',
    'forum',
    'otava',
    'eksil',
    'gripa',
    'obrok',
    'haiku',
    'volan',
    'zalet',
    'trska',
    'rilec',
    'pamet',
    'delta',
    'idila',
    'ikona',
    'graja',
    'aorta',
    'orkan',
    'tulec',
    'račka',
    'uteha',
    'vogal',
    'epoha',
    'cunja',
    'izlet',
    'copat',
    'kemik',
    'plima',
    'vzmet',
    'preša',
    'labod',
    'reber',
    'zarja',
    'mucek',
    'obvoz',
    'cimet',
    'logik',
    'čačka',
    'etika',
    'vzpon',
    'uhelj',
    'moški',
    'omama',
    'favna',
    'cekar',
    'bakla',
    'vrtec',
    'šaman',
    'deblo',
    'marec',
    'dihur',
    'ameba',
    'unija',
    'izsek',
    'naziv',
    'čorba',
    'racak',
    'ugled',
    'pokoj',
    'zvrst',
    'kvark',
    'slama',
    'hrvat',
    'glosa',
    'gusar',
    'cifra',
    'gliva',
    'ovčar',
    'atrij',
    'nateg',
    'požar',
    'sinus',
    'alarm',
    'upnik',
    'usnje',
    'samec',
    'fazan',
    'bivol',
    'beton',
    'zelje',
    'krota',
    'bajsi',
    'vidra',
    'šolar',
    'avtor',
    'izgon',
    'jajce',
    'zakon',
    'lotos',
    'ginko',
    'pismo',
    'finta',
    'javor',
    'jetra',
    'kamen',
    'idiom',
    'gvelf',
    'izvoz',
    'keber',
    'teslo',
    'krčma',
    'punca',
    'hokej',
    'ulica',
    'jelka',
    'ragbi',
    'pokol',
    'sunek',
    'goska',
    'cokla',
    'vršac',
    'kupon',
    'reklo',
    'krsta',
    'slovo',
    'nagib',
    'glina',
    'kalij',
    'burek',
    'zatič',
    'česen',
    'sonda',
    'zavod',
    'džins',
    'cezij',
    'hasek',
    'encim',
    'cepin',
    'kokoš',
    'ebola',
    'kopač',
    'harfa',
    'vegan',
    'himna',
    'čakra',
    'ugriz',
    'robot',
    'radar',
    'lutka',
    'prvak',
    'ukrep',
    'šepet',
    'žolna',
    'klovn',
    'bikec',
    'lišaj',
    'pobeg',
    'burja',
    'cinik',
    'griva',
    'endem',
    'nasip',
    'narek',
    'madež',
    'mufti',
    'želva',
    'lovec',
    'fojba',
    'cepec',
    'obris',
    'sleme',
    'fraza',
    'gorec',
    'pešec',
    'rakun',
    'vreča',
    'solza',
    'šerif',
    'odmik',
    'žeton',
    'kasko',
    'nabor',
    'rutka',
    'marža',
    'mivka',
    'pomoč',
    'deska',
    'ptica',
    'godec',
    'vojna',
    'bajer',
    'rokav',
    'dlaka',
    'jarem',
    'volna',
    'tujka',
    'davek',
    'pleme',
    'kazen',
    'drevo',
    'tekma',
    'skuta',
    'blago',
    'mlaka',
    'pasma',
    'falot',
    'farma',
    'navoj',
    'hudič',
    'afera',
    'donos',
    'odraz',
    'metek',
    'nafta',
    'grudi',
    'ničla',
    'slina',
    'izrez',
    'pišče',
    'junij',
    'balon',
    'ekipa',
    'datum',
    'groza',
    'april',
    'javka',
    'sluga',
    'kalup',
    'žužek',
    'alibi',
    'novec',
    'ujeda',
    'slika',
    'utaja',
    'pravo',
    'fosil',
    'toner',
    'lovor',
    'ekcem',
    'šavje',
    'buška',
    'murva',
    'rabin',
    'eksot',
    'jopič',
    'gosli',
    'bager',
    'evnuh',
    'izvir',
    'barje',
    'vinar',
    'vpliv',
    'plašč',
    'ataše',
    'teren',
    'sitar',
    'maslo',
    'efekt',
    'fakir',
    'dosje',
    'estet',
    'iskra',
    'frača',
    'izkop',
    'zavoj',
    'sivka',
    'potka',
    'palma',
    'jasli',
    'napad',
    'robec',
    'zrnje',
    'tiger',
    'kolač',
    'mevža',
    'škamp',
    'žepar',
    'kelih',
    'meter',
    'jadro',
    'izliv',
    'zgaga',
    'krilo',
    'očala',
    'fizik',
    'enota',
    'naboj',
    'šport',
    'trpin',
    'cmera',
    'rubež',
    'varuh',
    'julij',
    'opica',
    'drhal',
    'škrga',
    'letak',
    'polom',
    'emajl',
    'tobak',
    'lemur',
    'kiosk',
    'odbor',
    'glava',
    'žurer',
    'flirt',
    'hudir',
    'luska',
    'varda',
    'kreda',
    'srček',
    'požig',
    'zombi',
    'vedro',
    'hrošč',
    'hrček',
    'horda',
    'odsev',
    'sklic',
    'levak',
    'zrklo',
    'cikel',
    'kojot',
    'fičko',
    'točka',
    'klada',
    'renta',
    'norec',
    'fjord',
    'važič',
    'losos',
    'fokus',
    'norma',
    'haski',
    'vosek',
    'odlok',
    'islam',
    'danka',
    'preja',
    'mesar',
    'ideal',
    'junec',
    'zadek',
    'jamar',
    'trans',
    'bivak',
    'model',
    'radič',
    'lijak',
    'salon',
    'oglas',
    'barok',
    'roman',
    'denar',
    'nečak',
    'uspeh',
    'ladja',
    'resje',
    'hrana',
    'jelen',
    'elita',
    'boršč',
    'jutro',
    'enost',
    'halja',
    'revma',
    'dolar',
    'flaša',
    'korak',
    'hlače',
    'zveza',
    'ustje',
    'ščene',
    'avion',
    'gaber',
    'radij',
    'muzej',
    'vzvod',
    'vagon',
    'govor',
    'metla',
    'vlada',
    'veslo',
    'cesar',
    'odred',
    'ritem',
    'kučma',
    'priča',
    'čveka',
    'dekle',
    'tenis',
    'bruto',
    'obliž',
    'sosed',
    'vsota',
    'potop',
    'oltar',
    'narok',
    'brlog',
    'pojav',
    'vloga',
    'ženin',
    'kurir',
    'oslič',
    'pršut',
    'murka',
    'galij',
    'bizon',
    'stoik',
    'jezus',
    'mazač',
    'polet',
    'gosak',
    'indic',
    'hrbet',
    'mezga',
    'sanje',
    'senca',
    'golak',
    'samka',
    'dokup',
    'lučka',
    'nosek',
    'vespa',
    'nanos',
    'barva',
    'sirar',
    'pipec',
    'vadba',
    'čenča',
    'makro',
    'doseg',
    'torek',
    'sklep',
    'poden',
    'žička',
    'boter',
    'delec',
    'anime',
    'gnida',
    'shema',
    'žehta',
    'virus',
    'dirka',
    'opiat',
    'zumba',
    'zenit',
    'ducat',
    'žegen',
    'kunec',
    'agora',
    'tekač',
    'čuvaj',
    'ljubi',
    'kliše',
    'kocka',
    'orada',
    'nonet',
    'mrčes',
    'muren',
    'kreša',
    'truga',
    'stava',
    'mulat',
    'puška',
    'gonja',
    'poraz',
    'peron',
    'zgled',
    'sanke',
    'oklep',
    'pehar',
    'kužek',
    'blato',
    'resor',
    'obroč',
    'puder',
    'metež',
    'frfot',
    'višek',
    'odvod',
    'naval',
    'tumor',
    'pesek',
    'pista',
    'bejba',
    'čtivo',
    'gavda',
    'omika',
    'kripa',
    'modul',
    'torba',
    'obzir',
    'bučka',
    'polka',
    'nemir',
    'potek',
    'zrnce',
    'kašča',
    'bukla',
    'druid',
    'bonus',
    'lirik',
    'nihaj',
    'šipek',
    'fetiš',
    'psiha',
    'ročaj',
    'omaka',
    'draga',
    'dekla',
    'obrat',
    'kanal',
    'zakup',
    'stres',
    'godba',
    'čreda',
    'minus',
    'jelša',
    'balet',
    'agava',
    'eleat',
    'laser',
    'rubin',
    'zamah',
    'rožič',
    'farsa',
    'zasuk',
    'rizik',
    'sokol',
    'pivot',
    'pumpa',
    'pedal',
    'izbor',
    'birma',
    'setev',
    'skvot',
    'tatič',
    'basen',
    'odrez',
    'išias',
    'gnoza',
    'pisec',
    'cesta',
    'kaval',
    'veter',
    'patos',
    'čeber',
    'odpor',
    'bovla',
    'izraz',
    'puran',
    'odsek',
    'komik',
    'akril',
    'torzo',
    'karma',
    'aleja',
    'gneča',
    'tinta',
    'pokal',
    'teror',
    'mavec',
    'izvor',
    'vojak',
    'okvir',
    'baron',
    'žepek',
    'krona',
    'sedež',
    'tempo',
    'papež',
    'snaga',
    'suhec',
    'bomba',
    'petek',
    'komar',
    'anoda',
    'venec',
    'brioš',
    'šofer',
    'bacek',
    'sadje',
    'izhod',
    'tisoč',
    'klima',
    'litij',
    'tehno',
    'vizir',
    'oktan',
    'vodka',
    'mango',
    'butec',
    'arena',
    'lajež',
    'skiro',
    'golaž',
    'pluta',
    'odboj',
    'nasad',
    'zguba',
    'drozg',
    'moped',
    'ribič',
    'geslo',
    'izpuh',
    'odvoz',
    'hotel',
    'arhiv',
    'izrek',
    'risar',
    'čudak',
    'rafal',
    'odtis',
    'genom',
    'berač',
    'trava',
    'poker',
    'sonce',
    'urnik',
    'slana',
    'vstop',
    'potep',
    'svila',
    'delež',
    'zebra',
    'stvor',
    'vreme',
    'album',
    'semiš',
    'licej',
    'gobec',
    'sušec',
    'ocena',
    'tepež',
    'perla',
    'žival',
    'kipec',
    'bevsk',
    'kamin',
    'skuša',
    'nimfa',
    'salsa',
    'ponev',
    'poboj',
    'obliv',
    'mežik',
    'izkaz',
    'banja',
    'kriza',
    'sekta',
    'pirat',
    'preča',
    'rodeo',
    'čižek',
    'bohem',
    'pitje',
    'ključ',
    'režim',
    'živec',
    'demon',
    'cenik',
    'kosec',
    'pajek',
    'sežig',
    'cedra',
    'kafra',
    'splav',
    'pogan',
    'smuka',
    'napis',
    'bokal',
    'ogenj',
    'lakaj',
    'teloh',
    'penal',
    'bedro',
    'čopič',
    'kobra',
    'nalog',
    'lovka',
    'želja',
    'dedič',
    'aneks',
    'mehur',
    'gejša',
    'taksi',
    'vikar',
    'zobar',
    'opera',
    'freza',
    'radio',
    'polog',
    'steza',
    'žleza',
    'sepsa',
    'šakal',
    'sreda',
    'odmor',
    'borka',
    'talec',
    'odpad',
    'bajka',
    'splet',
    'vnema',
    'kulen',
    'objem',
    'šunka',
    'teden',
    'dekan',
    'vzdih',
    'kakav',
    'lesar',
    'žrtje',
    'plebs',
    'obtok',
    'leska',
    'sodba',
    'obraz',
    'plast',
    'modus',
    'štala',
    'omara',
    'bacil',
    'nemec',
    'petje',
    'nagec',
    'vizum',
    'peška',
    'ploha',
    'namig',
    'izris',
    'zamik',
    'zaton',
    'lokal',
    'rjuha',
    'jesen',
    'tango',
    'perje',
    'sklad',
    'posip',
    'testo',
    'mesto',
    'bunka',
    'kahla',
    'inčun',
    'drava',
    'tifus',
    'videc',
    'putka',
    'učilo',
    'spust',
    'greda',
    'sreča',
    'vitez',
    'olika',
    'fikus',
    'krača',
    'obred',
    'blisk',
    'kečap',
    'astma',
    'piška',
    'tenor',
    'bukva',
    'šiška',
    'strop',
    'deček',
    'packa',
    'birič',
    'polje',
    'oglar',
    'pupek',
    'župan',
    'klika',
    'oliva',
    'pomen',
    'odnos',
    'oseba',
    'ribez',
    'trola',
    'gmota',
    'tožba',
    'vezaj',
    'vrzel',
    'stran',
    'napuh',
    'natis',
    'kombi',
    'plato',
    'rovte',
    'gesta',
    'ričet',
    'kopje',
    'juriš',
    'garda',
    'golša',
    'tesar',
    'rolka',
    'start',
    'žvižg',
    'drama',
    'strel',
    'romar',
    'kefir',
    'telur',
    'menza',
    'oštir',
    'slast',
    'žamet',
    'večer',
    'pogon',
    'ohcet',
    'tlesk',
    'genij',
    'stroj',
    'tolpa',
    'titan',
    'bajta',
    'čuvar',
    'rimar',
    'zarez',
    'sifon',
    'grmič',
    'črnka',
    'odziv',
    'vatel',
    'vrata',
    'oblat',
    'narod',
    'mezda',
    'breza',
    'nagon',
    'hašiš',
    'pozer',
    'žabec',
    'tubit',
    'asket',
    'bazen',
    'butik',
    'rebus',
    'miško',
    'rudar',
    'kebab',
    'zefir',
    'mesec',
    'pomol',
    'dleto',
    'naliv',
    'točaj',
    'sadež',
    'filet',
    'vdova',
    'koran',
    'topol',
    'kisik',
    'smuči',
    'pojem',
    'lopar',
    'triko',
    'sivko',
    'žurka',
    'flora',
    'regal',
    'zanos',
    'mokar',
    'kadet',
    'tiara',
    'fatva',
    'čoban',
    'vezir',
    'kupid',
    'zobec',
    'pagat',
    'drska',
    'notar',
    'dioda',
    'papir',
    'goban',
    'sklon',
    'suita',
    'baker',
    'piton',
    'vidik',
    'zažig',
    'kader',
    'kupec',
    'brest',
    'junak',
    'pinca',
    'senat',
    'nižaj',
    'amper',
    'hobit',
    'tipka',
    'pajac',
    'mirta',
    'sejač',
    'poved',
    'serum',
    'mamka',
    'eskim',
    'pilot',
    'poziv',
    'tolar',
    'ščuka',
    'bukev',
    'tlaka',
    'letev',
    'sliva',
    'trnje',
    'miner',
    'vihra',
    'dinar',
    'dušik',
    'gobar',
    'zatok',
    'vrelo',
    'ponos',
    'panda',
    'mimik',
    'globa',
    'tkivo',
    'dopis',
    'tonik',
    'psica',
    'kraja',
    'molža',
    'možak',
    'print',
    'zahod',
    'vrvež',
    'rojak',
    'vršič',
    'izmik',
    'mačka',
    'falus',
    'zidak',
    'brizg',
    'kanta',
    'orgle',
    'nomad',
    'bolha',
    'poduk',
    'tunel',
    'bebec',
    'briga',
    'kovač',
    'sleng',
    'zasip',
    'odlom',
    'učivo',
    'občan',
    'vodik',
    'kasta',
    'kikla',
    'popis',
    'zakol',
    'dedec',
    'povoj',
    'namet',
    'motiv',
    'sodra',
    'kakec',
    'oksid',
    'pepel',
    'trakt',
    'odhod',
    'medij',
    'pohod',
    'pogoj',
    'etaža',
    'pogin',
    'odkup',
    'krzno',
    'šotor',
    'modem',
    'odeja',
    'pončo',
    'kljun',
    'vijak',
    'zidar',
    'padec',
    'salva',
    'oktet',
    'kravl',
    'galeb',
    'namaz',
    'sijaj',
    'stvar',
    'oblak',
    'zajec',
    'mafin',
    'motor',
    'brada',
    'slabo',
    'koral',
    'redar',
    'sezam',
    'cigan',
    'vokal',
    'škoda',
    'črevo',
    'žarek',
    'bober',
    'borba',
    'čemaž',
    'fižol',
    'koren',
    'rampa',
    'razum',
    'rebro',
    'plaža',
    'zlato',
    'karta',
    'namen',
    'polip',
    'gorje',
    'šopek',
    'pošta',
    'poseg',
    'posek',
    'tabor',
    'toast',
    'tarok',
    'pogum',
    'ideja',
    'posel',
    'menih',
    'krema',
    'trema',
    'bitka',
    'bilka',
    'belka',
    'pevka',
    'tepka',
    'vešča',
    'golob',
    'dijak',
    'konec',
    'pečat',
    'pekač',
    'paket',
    'odcep',
    'sonet',
    'gospa',
    'tujek',
    'tarot',
    'trema',
    'zagon',
    'pošta',
    'krava'
]
