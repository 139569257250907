import './App.css'
import { AlertContainer } from './components/alerts/AlertContainer'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { DatePickerModal } from './components/modals/DatePickerModal'
import { InfoModal } from './components/modals/InfoModal'
import { SettingsModal } from './components/modals/SettingsModal'
import { StatsModal } from './components/modals/StatsModal'
import { Navbar } from './components/navbar/Navbar'
import {
    DATE_LOCALE,
    DISCOURAGE_INAPP_BROWSERS,
    MAX_CHALLENGES,
    REVEAL_TIME_MS,
    WELCOME_INFO_MODAL_MS
} from './constants/settings'
import { CORRECT_WORD_MESSAGE, DISCOURAGE_INAPP_BROWSER_TEXT, WIN_MESSAGES } from './constants/strings'
import { useAlert } from './context/AlertContext'
import { isInAppBrowser } from './lib/browser'
import {
    getStoredIsHighContrastMode,
    loadGameStateFromLocalStorage,
    saveGameStateToLocalStorage,
    setStoredIsHighContrastMode
} from './lib/localStorage'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
    getGameDate,
    getIsLatestGame,
    isWinningWord,
    isWordInWordList,
    setGameDate,
    solution,
    solutionGameDate,
    unicodeLength
} from './lib/words'
import { ClockIcon } from '@heroicons/react/outline'
import { format } from 'date-fns'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import { useEffect, useState } from 'react'
import Div100vh from 'react-div-100vh'

function App() {
    const isLatestGame = getIsLatestGame()
    const gameDate = getGameDate()
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches

    const { showError: showErrorAlert, showSuccess: showSuccessAlert } = useAlert()
    const [currentGuess, setCurrentGuess] = useState('')
    const [isGameWon, setIsGameWon] = useState(false)
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
    const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
    const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false)
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
    const [currentRowClass, setCurrentRowClass] = useState('')
    const [isGameLost, setIsGameLost] = useState(false)
    const [isDarkMode, setIsDarkMode] = useState(
        localStorage.getItem('theme') ? localStorage.getItem('theme') === 'dark' : prefersDarkMode ? true : false
    )
    const [isHighContrastMode, setIsHighContrastMode] = useState(getStoredIsHighContrastMode())
    const [isRevealing, setIsRevealing] = useState(false)
    const [guesses, setGuesses] = useState<string[]>(() => {
        const loaded = loadGameStateFromLocalStorage(isLatestGame)
        if (loaded?.solution !== solution) {
            return []
        }
        const gameWasWon = loaded.guesses.includes(solution)
        if (gameWasWon) {
            setIsGameWon(true)
        }
        if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
            setIsGameLost(true)
            showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
                persist: true
            })
        }
        return loaded.guesses
    })

    const [stats, setStats] = useState(() => loadStats())

    useEffect(() => {
        // if no game state on load,
        // show the user the how-to info modal
        if (!loadGameStateFromLocalStorage(true)) {
            setTimeout(() => {
                setIsInfoModalOpen(true)
            }, WELCOME_INFO_MODAL_MS)
        }
    })

    useEffect(() => {
        DISCOURAGE_INAPP_BROWSERS &&
            isInAppBrowser() &&
            showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
                persist: false,
                durationMs: 7000
            })
    }, [showErrorAlert])

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }

        if (isHighContrastMode) {
            document.documentElement.classList.add('high-contrast')
        } else {
            document.documentElement.classList.remove('high-contrast')
        }
    }, [isDarkMode, isHighContrastMode])

    const handleDarkMode = (isDark: boolean) => {
        setIsDarkMode(isDark)
        localStorage.setItem('theme', isDark ? 'dark' : 'light')
    }

    const handleHighContrastMode = (isHighContrast: boolean) => {
        setIsHighContrastMode(isHighContrast)
        setStoredIsHighContrastMode(isHighContrast)
    }

    const clearCurrentRowClass = () => {
        setCurrentRowClass('')
    }

    useEffect(() => {
        saveGameStateToLocalStorage(getIsLatestGame(), { guesses, solution })
    }, [guesses])

    useEffect(() => {
        if (isGameWon) {
            const winMessage = WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
            const delayMs = REVEAL_TIME_MS * solution.length

            showSuccessAlert(winMessage, {
                delayMs,
                onClose: () => setIsStatsModalOpen(true)
            })
        }

        if (isGameLost) {
            setTimeout(() => {
                setIsStatsModalOpen(true)
            }, (solution.length + 1) * REVEAL_TIME_MS)
        }
    }, [isGameWon, isGameLost, showSuccessAlert])

    const onChar = (value: string) => {
        if (
            unicodeLength(`${currentGuess}${value}`) <= solution.length &&
            guesses.length < MAX_CHALLENGES &&
            !isGameWon
        ) {
            setCurrentGuess(`${currentGuess}${value}`)
        }
    }

    const onDelete = () => {
        setCurrentGuess(new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join(''))
    }

    const onEnter = () => {
        if (isGameWon || isGameLost) {
            return
        }

        if (!(unicodeLength(currentGuess) === solution.length)) {
            setCurrentRowClass('jiggle')
            return showErrorAlert('Premalo črk, kot juha brez rezancev!', {
                onClose: clearCurrentRowClass
            })
        }

        if (!isWordInWordList(currentGuess)) {
            setCurrentRowClass('jiggle')
            return showErrorAlert('Beseda ni najdena. Mogoče se je skrila za kakšno soglasnikom!', {
                onClose: clearCurrentRowClass
            })
        }

        setIsRevealing(true)
        // turn this back off after all
        // chars have been revealed
        setTimeout(() => {
            setIsRevealing(false)
        }, REVEAL_TIME_MS * solution.length)

        const winningWord = isWinningWord(currentGuess)

        if (unicodeLength(currentGuess) === solution.length && guesses.length < MAX_CHALLENGES && !isGameWon) {
            setGuesses([...guesses, currentGuess])
            setCurrentGuess('')

            if (winningWord) {
                if (isLatestGame) {
                    setStats(addStatsForCompletedGame(stats, guesses.length))
                }
                return setIsGameWon(true)
            }

            if (guesses.length === MAX_CHALLENGES - 1) {
                if (isLatestGame) {
                    setStats(addStatsForCompletedGame(stats, guesses.length + 1))
                }
                setIsGameLost(true)
                showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
                    persist: true,
                    delayMs: REVEAL_TIME_MS * solution.length + 1
                })
            }
        }
    }

    return (
        <Div100vh>
            <div className="flex h-full flex-col">
                <Navbar
                    setIsInfoModalOpen={setIsInfoModalOpen}
                    setIsStatsModalOpen={setIsStatsModalOpen}
                    setIsDatePickerModalOpen={setIsDatePickerModalOpen}
                    setIsSettingsModalOpen={setIsSettingsModalOpen}
                />

                {!isLatestGame && (
                    <div className="flex items-center justify-center">
                        <ClockIcon className="h-6 w-6 stroke-gray-600 dark:stroke-gray-300" />
                        <p className="text-base text-gray-600 dark:text-gray-300">
                            {format(gameDate, 'd MMMM yyyy', { locale: DATE_LOCALE })}
                        </p>
                    </div>
                )}

                <div className="mx-auto flex w-full grow flex-col px-1 pb-8 pt-2 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
                    <div className="flex grow flex-col justify-center pb-6 short:pb-2">
                        <Grid
                            solution={solution}
                            guesses={guesses}
                            currentGuess={currentGuess}
                            isRevealing={isRevealing}
                            currentRowClassName={currentRowClass}
                        />
                    </div>
                    <Keyboard
                        onChar={onChar}
                        onDelete={onDelete}
                        onEnter={onEnter}
                        solution={solution}
                        guesses={guesses}
                        isRevealing={isRevealing}
                    />
                    <InfoModal isOpen={isInfoModalOpen} handleClose={() => setIsInfoModalOpen(false)} />
                    <StatsModal
                        isOpen={isStatsModalOpen}
                        handleClose={() => setIsStatsModalOpen(false)}
                        solution={solution}
                        guesses={guesses}
                        gameStats={stats}
                        isLatestGame={isLatestGame}
                        isGameLost={isGameLost}
                        isGameWon={isGameWon}
                        isDarkMode={isDarkMode}
                        isHighContrastMode={isHighContrastMode}
                        numberOfGuessesMade={guesses.length}
                    />
                    <DatePickerModal
                        isOpen={isDatePickerModalOpen}
                        initialDate={solutionGameDate}
                        handleSelectDate={(d) => {
                            setIsDatePickerModalOpen(false)
                            setGameDate(d)
                        }}
                        handleClose={() => setIsDatePickerModalOpen(false)}
                    />
                    <SettingsModal
                        isOpen={isSettingsModalOpen}
                        handleClose={() => setIsSettingsModalOpen(false)}
                        isDarkMode={isDarkMode}
                        handleDarkMode={handleDarkMode}
                        isHighContrastMode={isHighContrastMode}
                        handleHighContrastMode={handleHighContrastMode}
                    />
                    <AlertContainer />
                </div>
            </div>
        </Div100vh>
    )
}

export default App
