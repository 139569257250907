export const WIN_MESSAGES = [
    'Bravo, ti besedni ninja!',
    'Fenomenalno, kot prašiček v blatu!',
    'Dobro opravljeno, kot pravi mojster zagonetk!'
]

export const HIGH_CONTRAST_MODE_DESCRIPTION = 'For improved color vision'
export const CORRECT_WORD_MESSAGE = (solution: string) => `The word was ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) => `Must use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) => `Guess must contain ${letter}`
export const STATISTICS_TITLE = 'Statistics'
export const GUESS_DISTRIBUTION_TEXT = 'Guess Distribution'
export const NEW_WORD_TEXT = 'New word in'
export const TOTAL_TRIES_TEXT = 'Total tries'
export const SUCCESS_RATE_TEXT = 'Success rate'
export const CURRENT_STREAK_TEXT = 'Current streak'
export const BEST_STREAK_TEXT = 'Best streak'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
    "You are using an embedded browser and may experience problems sharing or saving your results. We encourage you rather to use your device's default browser."

export const ARCHIVE_GAMEDATE_TEXT = 'Game date'
